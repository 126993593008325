import useConnection from './useConnection'

type FiltersValues = {
  filtersData: Record<string, any>
  filters: FiltersUI
}

/**
 * Hook Rest Detail
 * @param actionDispacher Function
 * @param id string
 * @param moduleName string
 */
export default function useFilters(): FiltersValues {
  const { filters, filtersData } = useConnection<{
    filters: FiltersUI
    filtersData: Record<string, any>
  }>(['appData.filtersData', 'appData.filters'])

  //console.log('rendering filters')
  //console.log(filters)
  //console.log(filtersData)

  //const customFilters = filters.filter()

  return {
    filtersData: filtersData,
    filters: filters,
  }
}
