import { renderForCriticalMoment } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsOxxo'

export default {
  title: 'Preguntas complementarias',
  description: 'Preguntas complementarias',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿El personal de OXXO GAS le indicó amablemente a qué bomba dirigirse?',
      position: [0, 0],
      size: [1, 2],
      showSample: false,
      indicator: 'PUMP_DELIVERY',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿El personal de OXXO GAS le ofreció algún servicio adicional de OXXO GAS?',
      position: [1, 0],
      size: [1, 2],
      showSample: false,
      indicator: 'ADDICIONAL_SERVICE',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Cuál (es) de estos servicios le ofrecieron?',
      position: [2, 0],
      size: [2, 2],
      showSample: false,
      indicator: 'ADDICIONAL_SERVICE_OPTIONS',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿El personal de OXXO GAS lo saludó cordialmente?',
      position: [0, 2],
      size: [1, 2],
      indicator: 'FRIENDLY_PERSONAL',
      showSample: false,
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿El personal de OXXO GAS lo despidió amablemente?',
      position: [1, 2],
      size: [1, 2],
      showSample: false,
      indicator: 'EXIT_GUIDE',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿Conoce el Programa Spin premia?',
      position: [2, 2],
      size: [1, 2],
      showSample: false,
      indicator: 'OXXO_PREMIA_KNOW',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿Nuestro colaborador le mencionó el programa spin premia?',
      position: [3, 2],
      size: [1, 2],
      showSample: false,
      indicator: 'OXXO_PREMIA_OFFER',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: '¿El personal de OXXO GAS le brindo un servicio ágil?',
      position: [0, 4],
      size: [1, 2],
      showSample: false,
      indicator: 'SERVICE_AGILE',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'atencionAgil',
      title: '¿Cuál (es) fue el momento en el que lo atendimos ágilmente?',
      position: [1, 4],
      size: [2, 2],
      showSample: false,
      indicator: 'SERVICE_AGILE_OPTIONS',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
      extras: {
        responseValues: { 1305: ['SI'] },
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'atencionNoAgil',
      title: '¿Cuál (es) fue el momento en el que no lo atendimos ágilmente?',
      position: [0, 6],
      size: [2, 2],
      showSample: false,
      indicator: 'SERVICE_AGILE_OPTIONS',
      criticalMomentInclude: [1, 11702],
      render: (filters) => renderForCriticalMoment(filters, [29, 1, 11702]),
      extras: {
        responseValues: { 1305: ['NO'] },
      },
    })
  ],
} as DasboardConfig
