export const ROLE_CODES = {
  REGIONAL_LEVEL: 'regionallevel',
  BRANCH_LEVEL: 'branchlevel',
  ZONE_LEVEL: 'zonelevel',
  STEERIING_COMMITTEE_LEVEL: 'steeringcommitteelevel',
  NATIONAL_LEVEL: 'nationallevel',
  MARKETING_LEVEL: 'marketinglevel',
  MC_LEVEL: 'mclevel',
  MISTERY_ROLE: 'misteryrole',
  EXCEUTIVE_ROLE: 'executiverole',
  SEGUROS_ROLE: 'segurosrole',
}

export const ROLE_CODES_LIST = Object.keys(ROLE_CODES).map(
  (role) => ROLE_CODES[role]
)

export const ROLES = {
  [ROLE_CODES.REGIONAL_LEVEL]: {
    id: 1,
    name: 'Regional Level',
    code: ROLE_CODES.REGIONAL_LEVEL,
  },
  [ROLE_CODES.BRANCH_LEVEL]: {
    id: 2,
    name: 'Branch Level',
    code: ROLE_CODES.BRANCH_LEVEL,
  },
  [ROLE_CODES.ZONE_LEVEL]: {
    id: 3,
    name: 'Zone Level',
    code: ROLE_CODES.ZONE_LEVEL,
  },
  [ROLE_CODES.STEERIING_COMMITTEE_LEVEL]: {
    id: 4,
    name: 'Steering Committee Level',
    code: ROLE_CODES.STEERIING_COMMITTEE_LEVEL,
  },
  [ROLE_CODES.NATIONAL_LEVEL]: {
    id: 5,
    name: 'National Level',
    code: ROLE_CODES.NATIONAL_LEVEL,
  },
  [ROLE_CODES.MARKETING_LEVEL]: {
    id: 6,
    name: 'Marketing Level',
    code: ROLE_CODES.MARKETING_LEVEL,
  },
  [ROLE_CODES.MC_LEVEL]: {
    id: 7,
    name: 'MC Level',
    code: ROLE_CODES.MC_LEVEL,
  },
  [ROLE_CODES.MISTERY_ROLE]: {
    id: 8,
    name: 'Mistery Role',
    code: ROLE_CODES.MISTERY_ROLE,
  },
  [ROLE_CODES.EXCEUTIVE_ROLE]: {
    id: 9,
    name: 'Excetuvie Role',
    code: ROLE_CODES.EXCEUTIVE_ROLE,
  },
  [ROLE_CODES.SEGUROS_ROLE]: {
    id: 10,
    name: 'Seguros Role',
    code: ROLE_CODES.SEGUROS_ROLE,
  },
}
