import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsGnp'

export default {
  title: 'Perdidas totales Dashboard',
  description: 'Perdidas totales Dashboard',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      //name: 'customLineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      showSample: false,
      indicator: 'pt_danosrep_sal_pregunta_1',
      criticalMomentInclude: [11607],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      extras: {
        periods: 6,
        groupByLevel: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'NPS',
      position: [0, 3],
      size: [1, 2],
      indicator: 'pt_danosrep_sal_pregunta_1',
      criticalMomentInclude: [11607],
      extraConfigs: {
        backgroundColor: '#5F2B87',
        sampleColor: '#5F2F82',
        fontColor: 'white',
      },
      mapper: (data, extra) => {
        return data.map((item: any, idx: any) => ({
          ...item,
          groupName: item.groupName + ' (' + item.value + ')'
        }))
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyResponsesTrend',
      title: 'Encuestas registradas',
      position: [1, 3],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [11607],
      extras: {
        workflowName: 'pt_danos'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      subTitle: '# de Encuestas por mes',
      position: [2, 3],
      size: [1, 2],
      showSample: false,
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [11607]
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      subTitle: '# de Encuestas por semana',
      position: [3, 3],
      size: [1, 2],
      showSample: false,
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [11607]
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Satisfacción',
      position: [0, 5],
      size: [1, 2],
      indicator: 'pt_danos_pregunta_2',
      criticalMomentInclude: [11607],
      mapper: (data, extra) => {
        return data.map((item: any, idx: any) => ({
          ...item,
          groupName: item.groupName + ' (' + item.value + ')'
        }))
      }
    }),
    /*getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Satisfacción',
      position: [0, 5],
      size: [1, 2],
      indicator: 'pt_danos_pregunta_2',
      criticalMomentInclude: [11607],
      extras: {
        featureInclude: ['zoomOut']
      }
    }),*/
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Tiempo para confirmar Perdida Total',
      position: [1, 5],
      size: [1, 2],
      indicator: 'pt_danos_pregunta_6',
      criticalMomentInclude: [11607],
      extras: {
        featureInclude: ['zoomOut']
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Seguimiento y asesoría de trámite pago',
      position: [2, 5],
      size: [1, 2],
      indicator: 'pt_danos_pregunta_7',
      criticalMomentInclude: [11607],
      extras: {
        featureInclude: ['zoomOut']
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Tiempo de respuesta de pago',
      position: [3, 5],
      size: [1, 2],
      indicator: 'pt_danos_pregunta_8',
      criticalMomentInclude: [11607],
      extras: {
        featureInclude: ['zoomOut']
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 7],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [11607]
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 9],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [11607]
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 13],
      size: [4, 3],
      mapper: (data, extra) => {
        return data.map((item: any) => ({
          ...item,
          tags: item.tags.filter((tag: any) => tag.group !== 'DEFAULT')
        }))
      },
      criticalMomentInclude: [11607],
    }),
  ],
} as DasboardConfig
