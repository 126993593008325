import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/users',
  layout: true,
  getComponent: async () => {
    const UsersLayout = await import(
      /* webpackChunkName: "users-modules" */ './components/UsersLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "users-modules" */ './UsersModule'
    )

    injectReducer(store, { key: 'users', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: UsersLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'users.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const UsersListView = await import(
          /* webpackChunkName: "users-modules" */ './components/UsersListView'
        )
        return {
          children: UsersListView.default,
        }
      },
    },

    {
      path: '/new',
      title: 'users.new_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const UsersFormView = await import(
          /* webpackChunkName: "users-modules" */ './components/UsersFormView'
        )
        return {
          children: UsersFormView.default,
        }
      },
    },

    {
      path: '/:id/edit',
      title: 'users.edit_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const UsersFormView = await import(
          /* webpackChunkName: "users-modules" */ './components/UsersFormView'
        )
        return {
          children: UsersFormView.default,
        }
      },
    },
  ],
})

