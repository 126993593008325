import { ApisauceInstance } from 'apisauce'
import dayjs from 'dayjs'
import _ from 'lodash'

/*
Channels
1 - Web
2 - Twitter
3 - Facebook
4 - Youtube
5 - Instagram
7 - Reviews
99 - Others (Linkedin, Reddit).
*/

const commarks = {
  427829: '',
  427830: '',
  427831: '',
  427838: '',
  427839: '',
  427840: '',
  427855: '',
  436826: '',
  436827: '',
  436828: '',
  436829: '',
  436830: '',
  436831: '',
  436832: '',
  436833: '',
  436834: '',
  436835: '',
  436836: '',
  436837: '',
  436838: '',
  436839: '',
  436840: '',
  436841: '',
  436842: '',
  436843: '',
  436844: '',
  436845: '',
  436846: '',
  436847: '',
  436848: '',
  436849: '',
  436850: '',
  436851: '',
  436852: '',
  436853: '',
  436854: '',
  436855: '',
  436856: '',
  436857: '',
  436858: '',
  436859: '',
  436860: '',
  436861: '',
  436862: '',
  436863: '',
  436864: '',
  436865: '',
  436866: '',
  436867: '',
  436868: '',
  436869: '',
  436870: '',
  436871: '',
  436872: '',
  436873: '',
  436874: '',
  436875: '',
  436876: '',
  436877: '',
  436878: '',
  436879: '',
  436880: '',
  436881: '',
  436882: '',
  436883: ''
}

const getAllCommarks = () => {
  return Object.keys(commarks).join('$')
}


const parseApiFilters = (filters: FiltersUI, extras?: any): Filters => {
  const dates = {
    dateFrom: dayjs(filters.fromDate).format('YYYY-MM-DD'),
    dateTo: dayjs(filters.toDate).format('YYYY-MM-DD'),
  }

  return {
    //token: process.env.MENTION_LYTICS_TOKEN ?? 'R12wVX609UYLeSA4Br7rnGjL3mKRXumQu-LTPmhbl1Is3RmfVeA4OxwGtA-6Rf1qgUDXYP3JCzxiU-zhUCo_5MDp',
    startDate: dates.dateFrom,
    endDate: dates.dateTo,
    channels: '1,2,3,4,5',
    language: 'es',
    ordering: 'date',
    // TODO: Traducir esto a geo locations
    commtracks: getAllCommarks()
  }
}


/**
 * Expose all endponts for 'Alerts'.
 */
export default <T>(api: ApisauceInstance): ApiObject => ({
  aggregation: (filters: FiltersUI) =>
    api.get('external-social/aggregation', { ...parseApiFilters(filters) }),

  mentions: (filters: FiltersUI) =>
    api.get('external-social/mentions', {
      ...parseApiFilters(filters),
      startDate: dayjs(filters.toDate).subtract(60, 'days').format('YYYY-MM-DD'),
      endDate: dayjs(filters.toDate).format('YYYY-MM-DD'),
      'per_page': 10000
    }),

  topKeywords: (filters: FiltersUI) =>
    api.get('external-social/top-keywords', { ...parseApiFilters(filters) }),

  analysis: (filters: FiltersUI) =>
    api.post('external-social/v3/analysis/overview/chart', { ...parseApiFilters(filters) }),
})
