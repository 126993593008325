import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/roles',
  layout: true,
  getComponent: async () => {
    const RolesLayout = await import(
      /* webpackChunkName: "roles-modules" */ './components/RolesLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "roles-modules" */ './RolesModule'
    )

    injectReducer(store, { key: 'roles', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: RolesLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'roles.list_title',
      getComponent: async () => {
        const RolesListView = await import(
          /* webpackChunkName: "roles-modules" */ './components/RolesListView'
        )
        return {
          children: RolesListView.default,
        }
      },
    },

    {
      title: 'roles.new_title',
      path: '/new',
      getComponent: async () => {
        const RolesFormView = await import(
          /* webpackChunkName: "roles-modules" */ './components/RolesFormView'
        )
  
        return {
          children: RolesFormView.default,
        }
      },
    },

    {
      title: 'roles.edit_title',
      path: '/:id/edit',
      getComponent: async () => {
        const RolesFormView = await import(
          /* webpackChunkName: "roles-modules" */ './components/RolesFormView'
        )
  
        return {
          children: RolesFormView.default,
        }
      },
    },

    {
      title: 'roles.detail_title',
      path: '/:id',
      getComponent: async () => {
        const RoleDetailView = await import(
          /* webpackChunkName: "roles-modules" */ './components/RoleDetailView'
        )
  
        return {
          children: RoleDetailView.default,
        }
      },
    },
  ],
})
