import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsOxxo'

export default {
  title: 'Bañios Dashboard',
  description: 'Dashboard de perfil baños',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
        {
      title: 'Encuestas',
      type: 'label',
      position: [0, 0],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
     {
      title: 'Tendencias',
      type: 'label',
      position: [3, 0],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 1],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [12951],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 1],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [12951],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Limpieza General',
      position: [2, 1],
      size: [1, 2],
      indicator: 'OXXO_BANIO_LIMPIEZA',
     /* mapper: (data, extra) => {
        return data.map((item: any, idx: any) => ({
          ...item,
          groupName: item.groupName + ' (' + item.value + ')'
        }))
      },
      */
      criticalMomentInclude: [12951],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Disponibilidad de suministros',
      position: [3, 1],
      size: [1, 2],
      indicator: 'OXXO_BANIO_SUMINISTROS',
      criticalMomentInclude: [12951],
    }),
     {
      title: 'Tendencias',
      type: 'label',
      position: [0,3 ],
      size: [1, 1],
      config: { color: '#B7AADC' },
    },
     {
      title: 'Sentimientos',
      type: 'label',
      position: [1, 3],
      size: [3, 1],
      config: { color: '#B7AADC' },
    },
      getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Nivel de mantenimiento',
      position: [0, 4],
      size: [1, 3],
      indicator: 'OXXO_BANIO_MANTENIMIENTO',
      criticalMomentInclude: [12951],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lastComments',
      position: [1, 4],
      size: [2, 3],
      indicator: 'OXXO_BANIO_COMENTARIOS',
      criticalMomentInclude: [12951],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [3, 4],
      size: [1, 3],
      criticalMomentInclude: [12951],
    }),
   
  ],
} as DasboardConfig
