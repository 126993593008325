import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes } from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
  {
    title: 'Principales Indicadores',
    position: [0, 0],
    size: [4, 1],
     type: 'label',
     config: { color: '#B7AADC' },
  },
  getProfuturoNpsAcumulado({
    position: [0, 1],
    size: [1, 2],
    indicator: 'app_pregunta_15'
  }),
  getProfuturoNpsPorMes({
    position: [1, 1],
    size: [1, 2],
    indicator: 'app_pregunta_15',
  }),
  getProfuturoFacilidad({
    position: [2, 1],
    size: [2, 2],
    indicator: 'app_pregunta_4',
    helpText: 'Pensando en la última consulta o trámite que hiciste a través de Profuturo Móvil, ¿qué tan fácil fue realizarlo?'
  }),
  {
    title: 'Motivadores y quiebres',
    position: [0, 3],
    size: [4, 1],
     type: 'label',
     config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCount',
    title: 'Facilidad',
    position: [0, 4],
    size: [2, 2],
    indicator: 'app_pregunta_5',
    helpText: '¿Nos podrás contar un poco acerca de esa calificación'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMedium',
    title: 'Consulta o tramite',
    position: [2, 4],
    size: [2, 2],
    indicator: 'app_pregunta_6',
    helpText: '¿Cuál fue el último trámite o consulta que realizaste a través de Profuturo Móvil?',
    extraConfigs: {
      colors: PRODUTURO_PIE_COLORS,
      itemGap: 6,
      center: ['27%', '55%'],
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericMediumSiNo',
    title: 'Efectividad',
    position: [0, 6],
    size: [2, 2],
    helpText: '¿Pudiste resolver tu trámite o consulta?',
    indicator: 'app_pregunta_7',
    extraConfigs: {
      itemGap: 10,
      center: ['27%', '55%'],
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCount',
    title: 'Motivos de la consulta no resuelta',
    position: [2, 6],
    size: [2, 2],
    helpText: '¿Por qué no pudiste resolver tu trámite o consulta?',
    indicator: 'app_pregunta_8'
  }),
  {
    title: 'Atributos de experiencia',
    position: [0, 8],
    size: [4, 1],
     type: 'label',
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'La rapidez y facilidad para ingresar a la app',
    position: [0, 9],
    size: [4, 1],
    helpText: 'Pensando en tu interacción a través de Profuturo Móvil, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
    indicator: 'app_pregunta_10'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'La facilidad para encontrar la información / trámite que necesitaba',
    position: [0, 10],
    size: [4, 1],
    helpText: 'Pensando en tu interacción a través de Profuturo Móvil, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
    indicator: 'app_pregunta_11'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'El modo en que pude resolver mis consultas / trámites',
    position: [0, 11],
    size: [4, 1],
    helpText: 'Pensando en tu interacción a través de Profuturo Móvil, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
    indicator: 'app_pregunta_12'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'Lo simple e intuitivo que resultó navegar la app',
    position: [0, 12],
    size: [4, 1],
    helpText: 'Pensando en tu interacción a través de Profuturo Móvil, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
    indicator: 'app_pregunta_13'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stackedBar',
    title: 'La claridad de la información que me brindó la app',
    position: [0, 13],
    size: [4, 1],
    helpText: 'Pensando en tu interacción a través de Profuturo Móvil, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
    indicator: 'app_pregunta_14'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    title: 'Análisis de Sentimientos',
    position: [0, 14],
    size: [4, 4],
    indicator: 'SENTIMENT'
  }),
]} as DasboardConfig
