import useConnection from './useConnection'

/**
 * Hook Account Data
 */
export default function useAccount(): UserAccount {

  const { data, } = useConnection<{
    data: UserAccount
  }>(['account.data',])

  return data
}
