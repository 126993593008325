import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/surveys',
  layout: true,
  getComponent: async () => {
    const SurveysAdminLayout = await import(
      /* webpackChunkName: "surveys-modules" */ './components/SurveysAdminLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "surveys-modules" */ './SurveysModule'
    )

    injectReducer(store, { key: 'surveys', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: SurveysAdminLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const SurveysListView = await import(
          /* webpackChunkName: "surveys-modules" */ './components/SurveysListView'
        )
        return {
          children: SurveysListView.default,
        }
      },
    },
    
    {
      title: 'surveys.new_title', // Here you can use a plain string o a transalation key
      path: '/new',
      getComponent: async () => {
        const SurveyWizardView = await import(
          /* webpackChunkName: "surveys-modules" */ './components/SurveyWizardView'
        )
    
        return {
          children: SurveyWizardView.default,
        }
      },
    },
  ],
})

