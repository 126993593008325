import React from 'react'
import { Spin } from 'antd'
import './BlockLoader.less'

/**
* BlockLoader
*/
const BlockLoader = (): JSX.Element => {
  return (
    <div className={'block-loader-component'}>
      <div className='blocker-loading'>
        <Spin size='large' />
      </div>
    </div>
  )
}

export default BlockLoader
