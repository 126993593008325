import { COLORS_CONFIG_BAR } from '@components/widgets/apacheEchart/Commons'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, getCmID } from './commonWidgetsSuzuki'

export default {
  title: 'Zonal Postventa Dashboard',
  description: 'Dashboard de Zonal Postventa',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 4],
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [1, 4],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: getCmID("servicio"),
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: [0, 6],
      size: [4, 2],
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      criticalMomentId: getCmID("servicio"),
        mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label:  'widgets.indicatorId.' + item.groupId
          }
        })
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 8],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: getCmID("servicio"),
      extraConfigs: {
        fontSize: '18px',
        }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 8],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: getCmID("servicio"),
      extraConfigs: {
        fontSize: '18px',
        }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'widgets.geoLocationMap',
      position: [0, 10],
      size: [2, 2],
      criticalMomentId: getCmID("servicio"),
      indicator: 'POSVENTA_NPS',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 10],
      size: [1, 2],
      extraConfigs: {
        fontSize: '18px',
        limit: 3
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 10],
      size: [1, 2],
      extraConfigs: {
        fontSize: '18px',
        limit: 3
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 12],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("servicio"),
    }),
    {
      title: 'widgets.zoneGeneralSatisfaction',
      // [Column, Row]
      position: [0, 16],
      size: [4, 2],
      config: {
        id: 'satisfaction-zona',
        type: 'rankingIPN',
        indicators: [
          {
            indicator: 'POSVENTA_NPS',
            keyExtract: ['group', 'value', 'groupCount', 'position'],
            grouped: 'geoLocation',
            label: 'group',
            value: 'position',
            singleton: true,
            limit: 5,
            extras: {
              aggregation: 'rankingPosition',
              featureInclude: ['zoomOut'],
            },
          },
        ],
      },
    },
    {
      title: 'widgets.distributorGeneralSatisfaction',
      // [Column, Row]
      position: [0, 18],
      size: [4, 2],
      config: {
        id: 'satisfaccion-dist',
        type: 'bar',
        fillMode: '',
        //colors: '#f38625',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: 'POSVENTA_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 3,
              criticalMomentId: getCmID('servicio'),
            },
          },
        ],
      },
    },
    {
      title: 'widgets.otherIndicators',
      type: 'label',
      position: [0, 20],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.paymentPostventa',
      position: [0, 21],
      size: [1, 2],
      indicator: 'POSVENTA_FACTURACION',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.budgetPostventa',
      position: [1, 21],
      size: [1, 2],
      indicator: 'POSVENTA_PRESUPUESTO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.repairPostventa',
      position: [2, 21],
      size: [1, 2],
      indicator: 'POSVENTA_REPARACION_VEHICULO',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.repairAvailablePostventa',
      position: [3, 21],
      size: [1, 2],
      indicator: 'POSVENTA_DISPONIBILIDAD_TALLER',
      criticalMomentId: getCmID("servicio"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.explicacionTrabajoPostventa',
      position: [0, 23],
      size: [1, 2],
      indicator: 'POSVENTA_EXPLICACION_TRABAJO',
      criticalMomentId: getCmID("servicio"),
    }),
      getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 25],
      size: [4, 2],
      criticalMomentId: getCmID("servicio"),
    }),
  ],
} as DasboardConfig
