import { init } from 'redux-acron'
import LocaleModule from './LocaleModule'
import AuthModule from './AuthModule'
import AccountModule from './AccountModule'
import AppDataModule from './AppDataModule'

const modules = init([
  LocaleModule,
  AuthModule,
  AccountModule,
  AppDataModule,
])

export default modules
