import { ApisauceInstance } from 'apisauce'

/**
 * Expose all endponts for 'auth'.
 */
export default (api: ApisauceInstance): ApiObject => {
  return {
    signin: (username: string, password: string) => {
      return api.post('/authenticate', { username, password, })
    },
  }
}
