import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

const DURANTE_CUSTOMER_JOURNEY = [
    'DURANTE_CUSTOMER_JOURNEY_ALIMENTOS',
    'DURANTE_CUSTOMER_JOURNEY_ENFERMERIA',
    'DURANTE_CUSTOMER_JOURNEY_PERSONAL_MEDICO'
]

const POSTERIOR_CUSTOMER_JOURNEY = [
    'POSTERIOR_CUSTOMER_JOURNEY_ADMISION',
    'POSTERIOR_CUSTOMER_JOURNEY_ALIMENTOS',
    'POSTERIOR_CUSTOMER_JOURNEY_ENFERMERIA',
    'POSTERIOR_CUSTOMER_JOURNEY_PERSONAL_MEDICO',
    'POSTERIOR_CUSTOMER_JOURNEY_ALTA',
    'POSTERIOR_CUSTOMER_JOURNEY_SEGURO'
]

export const omoikaneHA: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        //OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 3,
    indicators: [
        ...DURANTE_CUSTOMER_JOURNEY.map((cj: any) => ({
            indicator: cj,
            keyExtract: [],
            //grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        })),
        ...POSTERIOR_CUSTOMER_JOURNEY.map((cj: any) => ({
            indicator: cj,
            keyExtract: [],
            //grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        })),
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-durante',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [9951]
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-posterior',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [10301]
            }
        },
        {
            indicator: 'durante_a_pregunta_41',
            key: 'satisfaction-durante',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [9951]
            }
        },
        {
            indicator: 'posterior_a_pregunta_54',
            key: 'satisfaction-posterior',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [10301]
            }
        },
        {
            indicator: 'durante_a_pregunta_6',
            key: 'nps-durante',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [9951]
            }
        },
        {
            indicator: 'posterior_a_pregunta_5',
            key: 'nps-posterior',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [10301]
            }
        }/*,
        {
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            key: 'survey-count-durante',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [9951]
            }
        },
        {
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            key: 'survey-count-posterior',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [10301]
            }
        },*/
    ],
    sentiment: {
        indicators: {
            DURANTE: 'sentiment-durante',
            POSTERIOR: 'sentiment-posterior'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            DURANTE: [
                { order: 1, name: 'NPS', key: 'nps-durante', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-durante', widgetType: 'gauge' },
            ],
            POSTERIOR: [
                { order: 1, name: 'NPS', key: 'nps-posterior', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-posterior', widgetType: 'gauge' },
            ]
        }
    },
    clusters: {},
    customerJourney: {
        DURANTE: (data: any) => {
            return DURANTE_CUSTOMER_JOURNEY.map((cj: any, idx: number) => {
                const customerJourney = data[cj]
                const value = customerJourney !== undefined
                    && customerJourney.data !== undefined
                    && customerJourney.data[0] !== undefined
                    ? customerJourney.data[0]?.value
                    : undefined

                return {
                    key: idx,
                    number: (idx + 1).toString(),
                    title: 'customer_journeys.' + cj,
                    items: [
                        { order: 1, name: 'NPS', value: value }
                    ]
                }
            })
        },
        POSTERIOR: (data: any) => {
            return POSTERIOR_CUSTOMER_JOURNEY.map((cj: any, idx: number) => {
                const customerJourney = data[cj]
                const value = customerJourney !== undefined
                    && customerJourney.data !== undefined
                    && customerJourney.data[0] !== undefined
                    ? customerJourney.data[0]?.value
                    : undefined

                return {
                    key: idx,
                    number: (idx + 1).toString(),
                    title: 'customer_journeys.' + cj,
                    items: [
                        { order: 1, name: 'NPS', value: value }
                    ]
                }
            })
        },
    }
}
