import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/action-plans',
  layout: true,
  getComponent: async () => {
    const ActionPlansLayout = await import('./components/ActionPlansLayout')
    const { reducer, rootSagas, } = await import('./ActionPlansModule')

    injectReducer(store, { key: 'actionPlans', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: ActionPlansLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/:id/detail',
      title: 'Detalle de Plan de Acción', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ActionPlansDetailView = await import('./components/ActionPlansDetailView')
        return {
          children: ActionPlansDetailView.default,
        }
      },
    },
    {
      path: '/new',
      title: 'Nuevo Plan de Acción', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ActionPlanCreateView = await import('./components/ActionPlanCreateView')
        return {
          children: ActionPlanCreateView.default,
        }
      },
    },
    {
      path: '/:id?/',
      title: 'Planes de Acción', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ActionPlansStatsView = await import('./components/ActionPlansStatsView')
        return {
          children: ActionPlansStatsView.default,
        }
      },
    },
    {
      path: '/best-practices/library',
      title: 'Detalle de Mejores Practicas', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const BestPracticesDetailView = await import('./components/BestPracticesDetailView')
        return {
          children: BestPracticesDetailView.default,
        }
      },
    },
  ],
})

