import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/power-intelligence',
  layout: true,
  getComponent: async () => {
    const PowerIntelligenceLayout = await import('./components/PowerIntelligenceLayout')
    const { reducer, rootSagas, } = await import('./PowerIntelligenceModule')

    injectReducer(store, { key: 'powerIntelligence', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: PowerIntelligenceLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'Power Intelligence', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const PowerIntelligenceView = await import('./components/PowerIntelligenceView')
        return { children: PowerIntelligenceView.default, }
      },
    },
  
  ],
})

