import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/companies',
  layout: true,
  getComponent: async () => {
    const CompaniesLayout = await import(
      /* webpackChunkName: "companies-modules" */ './components/CompaniesLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "companies-modules" */ './CompaniesModule'
    )

    const { reducer: adminReducer, rootSagas: adminRootSagas, } = await import(
      /* webpackChunkName: "companies-modules" */ './AdminsModule'
    )

    const { reducer: usersReducer, rootSagas: usersRootSagas, } = await import(
      /* webpackChunkName: "companies-modules" */ './UsersModule'
    )

    injectReducer(store, { key: 'companies', reducer, })
    injectReducer(store, { key: 'companiesAdmins', reducer: adminReducer, })
    injectReducer(store, { key: 'companiesUsers', reducer: usersReducer, })
    injectSagas(store, rootSagas)
    injectSagas(store, adminRootSagas)
    injectSagas(store, usersRootSagas)

    return {
      children: CompaniesLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'companies.list_title',
      getComponent: async () => {
        const CompaniesListView = await import(
          /* webpackChunkName: "companies-modules" */ './components/CompaniesListView'
        )
        return {
          children: CompaniesListView.default,
        }
      },
    },

    {
      path: '/new',
      title: 'companies.new_title',
      getComponent: async () => {
        const CompaniesFormView = await import(
          /* webpackChunkName: "companies-modules" */ './components/CompaniesFormView'
        )
        return {
          children: CompaniesFormView.default,
        }
      },
    },

    {
      path: '/:id/edit',
      title: 'companies.edit_title',
      getComponent: async () => {
        const CompaniesFormView = await import(
          /* webpackChunkName: "companies-modules" */ './components/CompaniesFormView'
        )
        return {
          children: CompaniesFormView.default,
        }
      },
    },

    {
      path: '/:id',
      title: 'companies.detail_title',
      getComponent: async () => {
        const CompaniesDetailView = await import(
          /* webpackChunkName: "companies-modules" */ './components/CompaniesDetailView'
        )
        return {
          children: CompaniesDetailView.default,
        }
      },
    },
  ],
})

