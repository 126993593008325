import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import {
  //COLORS_CONFIG,
  //COLORS_CONFIG_BAR,
  commonWidgets,
} from './commonWidgetsMitsu'

export default {
  title: 'Nacional Reparación Dashboard',
  description: 'Dashboard de Nacional Postventa',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snReparacion',
      position: [0, 0],
      size: [1, 2],
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsReparacion',
      position: [1, 0],
      size: [1, 2],
      indicator: 'REPARACION_NPS',
      criticalMomentId: 3,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosReparacion',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasReparacion',
      position: [3, 0],
      size: [1, 2],
      mapper: answeredMapper,
      url: 'survey-responses/values',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyReparacion',
      position: [0, 2],
      size: [4, 2],
      indicator: 'REPARACION_CUSTOMER_JOURNEY',
      criticalMomentId: 3
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsReparacion',
      position: [0, 4],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsReparacion',
      position: [2, 4],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'REPARACION_SATISFACCION',
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 6],
      size: [4, 4],
      criticalMomentId: 3,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 10],
      size: [2, 2],
      indicator: 'WORD_COUNT_WITH_LINKS',
      criticalMomentId: 3,
    })
  ],
} as DasboardConfig
