/*eslint no-empty-function: 0 */
import React from 'react'

type PropTypes = {
  children?: Array<React.ReactNode> | React.ReactNode
}

/**
 * 
 */
const MenuStateContext = React.createContext({
  collapsed: true,
  setCollapsed: (collapsed: boolean) => {},
})

export const MenuStateContextProvider = ({ children, }: PropTypes): JSX.Element => {
  const [ collapsed, setCollapsed,] = React.useState<boolean>(true)

  return (
    <MenuStateContext.Provider value={{ collapsed, setCollapsed, }}>
      {children}
    </MenuStateContext.Provider>
  )
}

//Use menustate...
export default (): {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}  => React.useContext(MenuStateContext)
