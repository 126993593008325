export default (): RouteConfig => ({
  path: '/reset',
  getComponent: async () => {
    const { default: ResetPasswordView, } = await import(
      /* webpackChunkName: "reset-password" */ './components/ResetPasswordView'
    )

    return {
      children: ResetPasswordView,
    }
  },
})
