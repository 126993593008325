import { useSelector } from 'react-redux'
import { isEqual, get, isArray } from 'lodash'

const getKeyName = (key: string) => {
  const keyList = key.split('.')
  const lastItem = keyList.pop()

  if (lastItem) {
    return lastItem
  }
  return key
}

export default function useConnection<Result>(
  keys: Array<string> | string
): Result {
  const data = useSelector((state) => {
    const results = {} as Result
    const values = isArray(keys)
      ? keys.reduce<Result>((res: Result, key: string) => {
          res[getKeyName(key)] = get(state, key)
          return res
        }, results)
      : get(state, keys)
    return values
  }, isEqual)

  return data
}
