import { getCommonWidget } from "../dashWidgetHelper"
import { commonWidgets, getCmID } from "./commonWidgetsSuzuki"
export default {
  title: "Distribuidor Dashboard",
  description: "Dashboard de Distribuidor",
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: getCmID("venta"),
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 4],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      indicator: 'VENTA_NPS',
      position: [1, 4],
      size: [1, 2],
      criticalMomentId: getCmID("venta"),
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label:  'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      position: [0, 6],
      size: [4, 2],
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 8],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("venta"),
    }),
    {
      title: "widgets.rankingZoneDistribuidores",
      position: [0, 12],
      size: [2, 1],
      config: {
        id: "venta-ranking-zonas",
        type: "rankingIPN",
        /*mapper: (data: any) => {
          return data.filter((item: any) => item.id == 1)
        },*/
        indicators: [
          {
            indicator: "VENTA_SATISFACCION",
            keyExtract: ["group", "position", "groupCount"],
            grouped: "geoLocation",
            label: "group",
            value: "position",
            singleton: true,
            sort: { value: "desc" },
            //limit: 1,
            extras: {
              aggregation: "rankingPosition",
              rankingPositionScope: 0,
              //featureInclude: ['zoomOut'],
              criticalMomentId: getCmID("venta"),
            },
          },
        ],
      },
    },
    {
      title: "widgets.rankingNationalDistribuidores",
      position: [2, 12],
      size: [2, 1],
      config: {
        id: "venta-ranking-nacional",
        type: "rankingIPN",
        indicators: [
          {
            indicator: "VENTA_SATISFACCION",
            keyExtract: ["group", "position", "groupCount"],
            grouped: "geoLocation",
            label: "group",
            value: "position",
            singleton: true,
            sort: { value: "desc" },
            limit: 1,
            extras: {
              aggregation: "rankingPosition",
              rankingPositionScope: 100,
              criticalMomentId: getCmID("venta"),
            },
          },
        ],
      },
    },
    {
      title: "widgets.otherIndicators",
      type: "label",
      position: [0, 13],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: "widgets.warrantyExplanation",
      position: [0, 14],
      size: [2, 2],
      indicator: 'VENTA_BENEFICIOS',
      mapper: (data) => {
        return data.map((item) => ({
          id: item["group"] == null ? "Indefinido" : item["group"],
          label: item["group"] == null ? "Indefinido" : item["group"],
          value: item["value"],
        }))
      },
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.entregaVenta',
      position: [2, 14],
      size: [1, 2],
      indicator: 'VENTA_ENTREGA',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.upsellingVenta',
      position: [3, 14],
      size: [1, 2],
      indicator: 'VENTA_ACCESORIOS_ADICIONALES',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.instalationsVenta',
      position: [0, 16],
      size: [1, 2],
      indicator: 'VENTA_INSTALACIONES',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.confirmacionEntregaVenta',
      position: [1, 16],
      size: [1, 2],
      indicator: 'VENTA_CONFIRMACION_ENTREGA',
      criticalMomentId: getCmID("venta"),
    }),
     getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'widgets.geoLocationMap',
      position: [2, 16],
      size: [2, 2],
      criticalMomentId: getCmID("venta"),
      indicator: 'VENTA_NPS',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 18],
      size: [4, 2],
      criticalMomentId: getCmID("venta"),
    }),
  ],
} as DasboardConfig
