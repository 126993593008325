// function sleep(ms: number) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

export default (): RouteConfig => ({
  path: '/',
  getComponent: async () => {
    const { default: LoginView, } = await import(/* webpackChunkName: "login-modules" */ './components/LoginView')
    // await sleep(500)
    return {
      children: LoginView,
    }
  },
})
