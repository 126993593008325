/* eslint-disable no-underscore-dangle */
import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore as createReduxStore,
  Middleware,
  Reducer,
} from 'redux'
// import { persistReducer, persistStore } from 'redux-persist'
import { History } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware, { Saga } from 'redux-saga'
import config from '@config'
import { persistStore } from 'redux-persist'

const createStore = (
  history: History,
  makeRootReducer: () => Reducer<any, AnyAction>,
  rootSaga: Saga,
): Store => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = []
  const sagaMiddleware = createSagaMiddleware()
  middleware.push(sagaMiddleware)
  middleware.push(routerMiddleware(history))

  /* ----------- Api Middleware --------------- */
  // middleware.push(interceptorMiddleware(api))

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers: Middleware[] = []
  let composeEnhancers = compose

  if (process.env.NODE_ENV === 'development') {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      /* tslint:disable */
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    }
  }

  const reducers = makeRootReducer()
  
  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store: Store = createReduxStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(...middleware), ...enhancers),
  )

  // ======================================================
  // Redux Persist Setup
  // ======================================================
  if (config.persistConfig.active) {
    // @ts-ignore
    store.persistor = persistStore(store, { manualPersist: true, })
  }

  store.asyncReducers = {}
  store.runSaga = sagaMiddleware.run
  // kick off root saga
  sagaMiddleware.run(rootSaga)

  if ((module as any).hot) {
    (module as any).hot.accept('../modules/reducers', async () => {
      const redoReducers = (await import('../modules/reducers')).default
      store.replaceReducer(redoReducers(store.asyncReducers))
    })
  }

  return store
}

export default createStore
