import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/reports',
  layout: true,
  getComponent: async () => {
    const ReportsLayout = await import(
      /* webpackChunkName: "reports-modules" */ './components/ReportsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "reports-modules" */ './ReportsModule'
    )

    injectReducer(store, { key: 'reports', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: ReportsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'reports.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ReportsListView = await import(
          /* webpackChunkName: "reports-modules"*/ './components/ReportsListView'
        )
        return {
          children: ReportsListView.default,
        }
      },
    },
    
    // {
    //   title: '', // Here you can use a plain string o a transalation key
    //   path: '/other-sub-path',
    //   getComponent: async () => {
    //     const OtherView = await import(
    //       /* webpackChunkName: "reports-modules"*/ './components/OtherView'
    //     )
    //
    //     return {
    //       children: OtherView.default,
    //     }
    //   }
    // },
  ],
})

