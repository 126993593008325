import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/geo-distributions',
  layout: true,
  getComponent: async () => {
    const GeoDistributionsLayout = await import(
      /* webpackChunkName: "geo-distributions-modules" */ './components/GeoDistributionsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "geo-distributions-modules" */ './GeoDistributionsModule'
    )

    injectReducer(store, { key: 'geoDistributions', reducer, })
    injectSagas(store, rootSagas)

    return {
      children: GeoDistributionsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'geo_distributions.list_title',
      getComponent: async () => {
        const GeoDistributionsListView = await import(
          /* webpackChunkName: "geo-distributions-modules" */ './components/GeoDistributionsListView'
        )
        return {
          children: GeoDistributionsListView.default,
        }
      },
    },

    {
      title: 'geo_distributions.new_title',
      path: '/new',
      getComponent: async () => {
        const GeoDistributionsFormView = await import(
          /* webpackChunkName: "geo-distributions-modules" */ './components/GeoDistributionsFormView'
        )
  
        return {
          children: GeoDistributionsFormView.default,
        }
      },
    },

    {
      title: 'geo_distributions.new_title',
      path: '/edit/:id',
      getComponent: async () => {
        const GeoDistributionsFormView = await import(
          /* webpackChunkName: "geo-distributions-modules" */ './components/GeoDistributionsFormView'
        )
  
        return {
          children: GeoDistributionsFormView.default,
        }
      },
    },
  ],
})
