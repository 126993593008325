/*eslint no-empty-function: "off"*/
import React from 'react'

type PropTypes = {
  children?: Array<React.ReactNode> | React.ReactNode
}

/**
 * 
 */
const ScrollerContext = React.createContext({
  layout: React.createRef(),
  // @ts-ignore
  setLayout: (element: any) => {},
})

export const ScrollerProvider = ({ children, }: PropTypes): JSX.Element => {
  const layout = React.useRef(null)
  const setLayout = (element: any) => {
    layout.current = element
  }

  return (
    <ScrollerContext.Provider value={{ layout, setLayout, }}>
      {children}
    </ScrollerContext.Provider>
  )
}

//Use scroller...
export default (): {
  layout: React.RefObject<unknown>;
  setLayout: (element: any) => void;
}  => React.useContext(ScrollerContext)
