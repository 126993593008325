import config from '@config'
import { find } from 'lodash'

/**
 * Return the list of domain where sso its enabled.
 * @returns string[]
 */
export const activeDomains = (): string[] => {
  return config.sso.reduce(
    (result: string[], value: Record<string, string>) => {
      result.push(value.domain)
      return result
    },
    []
  )
}

/**
 * Checks if the domain passed as a parameter has sso enabled.
 * @param domain string
 * @returns boolean
 */
export const isSsoActive = (domain: string): boolean => {
  const domains = activeDomains()
  //return !!find(domains, (dmn) => domain.indexOf(dmn) > -1)
  return false
}

/**
 * Find the SSO config for a given domain.
 * @param domain string
 * @returns object
 */
export const getSsoConfig = (
  domain: string
): { cookieName: string; domain: string; loginURL: string } | undefined => {
  return find(config.sso, (config) => domain.indexOf(config.domain) > -1)
}

export default {
  activeDomains,
  isSsoActive,
}
