import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store) => ({
  path: '/employee-exp',
  layout: true,
  getComponent: async () => {
    const EmployeeExpLayout = await import(
      /* webpackChunkName: "employee-exp-modules" */ './components/EmployeeExpLayout'
    )
    const { reducer, rootSagas } = await import(
      /* webpackChunkName: "employee-exp-modules" */ './EmployeeExpModule'
    )

    injectReducer(store, { key: 'employeeExp', reducer })
    injectSagas(store, rootSagas)

    return {
      children: EmployeeExpLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'Experiencia del Empleado', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const EmployeeExpView = await import(
          /* webpackChunkName: "employee-exp-modules"*/ './components/EmployeeExpView'
        )
        return {
          children: EmployeeExpView.default,
        }
      },
    },

    // {
    //   title: '', // Here you can use a plain string o a transalation key
    //   path: '/other-sub-path',
    //   getComponent: async () => {
    //     const OtherView = await import(
    //       /* webpackChunkName: "employee-exp-modules"*/ './components/OtherView'
    //     )
    //
    //     return {
    //       children: OtherView.default,
    //     }
    //   }
    // },
  ],
})
