
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  textAnalysis: {
    title: 'Análisis de sentimientos',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'text-analysis-qr',
      type: 'textAnalysis',
      indicators: [
        {
          indicator: 'SENTIMENT',
          grouped: 'tag',
          keyExtract: ['categories', 'count'],
          singleton: false,
          value: 'value',
          extras: {},
        },
      ],
    },
  },
  genericTrend: {
    title: 'NPS',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'nps-trend',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      showTrend: true,
      decimals: 2,
      multiIndicator: true,
      indicators: [
        {
          indicator: 'durante_a_pregunta_6',
          grouped: 'formattedMonth',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            featureInclude: ['zoomOut']
          },
        }
      ],
    },
  },
}

