import RestApiBuilder from '@utils/RestApiBuilder'
import parseFilters from '@utils/parseFilters'
import parseQuery from '@utils/parseQuery'
import { ApisauceInstance } from 'apisauce'
import _ from 'lodash'

/**
 * Expose all endponts for 'reports'.
 */
export default <T>(api: ApisauceInstance): ApiObject => ({
  ...RestApiBuilder<T>('dynamic-reports', api),
  getFields: (page: number, size: number, filters?: FiltersUI, query?: object, sort?: string) => {
    return api.get('dynamic-reports/fields', {
      page,
      ...(size && { size, }),
      ...(query && !_.isEmpty(query) && { filter: parseQuery(query), }),
      ...(sort && { sort, }),
      ...(filters && parseFilters(filters)),
    })
  },
  getData: (page: number, size: number, sort?: string, filters?: PIFilters) => {
    return api.post('reports/dynamic', {
      page,
      ...(size && { size, }),
      ...(sort && { sort, }),
      ...({
        ...filters,
        resultType: 'JSON'
      }),
    })
  },
  download: (
    page: number,
    size: number,
    sort?: string,
    filters?: PIFilters,
    type = 'application/vnd.ms-excel'
  ) => {

    const parsedFilters = {
      page,
      ...(size && { size, }),
      ...(sort && { sort, }),
      ...({
        ...filters,
        resultType: 'EXCEL'
      }),
    }

    const headers = {
      accept: type,
      //'content-type': type,
    }

    return api.post('reports/dynamic', parsedFilters, {
      responseType: 'blob',
      headers: headers,
    })
  },
})
