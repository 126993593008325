import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion } from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Principales indicadores',
      position: [0, 0],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
      position: [0, 1],
      size: [1, 2],
      indicator: 'acomp_pregunta_17'
    }),
    getProfuturoNpsPorMes({
      position: [1, 1],
      size: [1, 2],
      indicator: 'acomp_pregunta_17'
    }),
    getProfuturoSatisfaccion({
      position: [2, 1],
      size: [1, 2],
      indicator: 'acomp_pregunta_2',
      helpText: '¿Qué tan satisfecho estás con el acompañamiento que has recibido durante tu trámite para pensionarte?'
    }),
    getProfuturoAtencion({
      position: [3, 1],
      size: [1, 2],
      indicator: 'acomp_pregunta_12',
      helpText: 'Teniendo en cuenta el trato que has recibido, ¿cómo calificarías la atención recibida?'
    }),
    {
      title: 'Principales indicadores',
      position: [0, 3],
      size: [1, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    {
      title: 'Motivadores y quiebres',
      position: [1, 3],
      size: [3, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoFacilidad({
      position: [0, 4],
      size: [1, 2],
      indicator: 'acomp_pregunta_15',
      helpText: 'Pensando en el trámite para obtener tu pensión, ¿qué tan fácil te resultó?'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Satisfacción: clientes insatisfechos',
      position: [1, 4],
      size: [3, 2],
      helpText: 'Lamentamos que no estés satisfecho con esta experiencia, ¿podrías contarnos cuáles son los motivos por los cuales no fue una buena experiencia?',
      indicator: 'acomp_pregunta_5'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Satisfacción: clientes neutros',
      position: [0, 6],
      size: [2, 2],
      helpText: 'Nos gustaría mejorar tu experiencia y la de los clientes que estén próximos a pensionarse, ¿podrías contarnos cómo mejorar?',
      indicator: 'acomp_pregunta_4'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Satisfacción: clientes satisfechos',
      position: [2, 6],
      size: [2, 2],
      helpText: '¡Qué bueno que estés satisfecho! ¿Podrías contarnos las razones por la cuales la experiencia resultó buena?',
      indicator: 'acomp_pregunta_3'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención: clientes neutros e insatisfechos',
      position: [0, 8],
      size: [2, 2],
      indicator: 'acomp_pregunta_14',
      helpText: '¿En qué podríamos mejorar?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Atención: clientes satisfechos',
      position: [2, 8],
      size: [2, 2],
      indicator: 'acomp_pregunta_13',
      helpText: '¿Qué fue lo que más valoraste de la interacción?',
      extraConfigs: {
        colors: PRODUTURO_PIE_COLORS,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Dificultades con el trámite',
      helpText: ' ¿Podrías contarnos los motivos por los cuales el trámite para obtener tu pensión no te ha resultado fácil?',
      position: [0, 10],
      size: [4, 2],
      indicator: 'acomp_pregunta_16'
    }),
    {
      title: 'Atributos de experiencia',
      position: [0, 12],
      size: [4, 1],
      type: 'label',
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La claridad de las explicaciones y el conocimiento acerca del trámite',
      position: [0, 13],
      size: [4, 1],
      helpText: 'Pensando en el acompañamiento que has recibido durante el trámite para pensionarte, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      indicator: 'acomp_pregunta_7'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La seguridad que sentí al compartir datos personales durante cada etapa del proceso',
      position: [0, 14],
      size: [4, 1],
      helpText: 'Pensando en el acompañamiento que has recibido durante el trámite para pensionarte, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      indicator: 'acomp_pregunta_8',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La asesoría que recibí para tomar las mejores decisiones',
      position: [0, 15],
      size: [4, 1],
      helpText: 'Pensando en el acompañamiento que has recibido durante el trámite para pensionarte, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      indicator: 'acomp_pregunta_9'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'La explicación sobre los requisitos',
      position: [0, 16],
      size: [4, 1],
      helpText: 'Pensando en el acompañamiento que has recibido durante el trámite para pensionarte, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      indicator: 'acomp_pregunta_10'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: 'El seguimiento del trámite por parte de Profuturo',
      position: [0, 17],
      size: [4, 1],
      helpText: 'Pensando en el acompañamiento que has recibido durante el trámite para pensionarte, ¿qué tan satisfecho estás con cada uno de los siguientes aspectos?',
      indicator: 'acomp_pregunta_11'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Comentarios',
      position: [0, 18],
      size: [4, 2],
      helpText: 'Por último, ¿tienes alguna sugerencia para mejorar tu experiencia con rofuturo?',
      indicator: 'acomp_pregunta_18'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 20],
      size: [4, 3],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [9951]
    }),
  ]
} as DasboardConfig
