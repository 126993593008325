import { filterGeos } from '@utils/GeoUtils'


export default {
  title: 'Benamedic Dashboard',
  description: 'Dashboard de Benamedic',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
  /*
  {
    title: 'Satisfacción General por localización',
    position: [0, 0,],
    size: [4, 2,],
    config: {
      id: 'satisfaction-general-bar',
      type: 'bar',
      fillMode: '',
      colors: '#f38625',
      showValues: true,
      indicators: [
        {
          indicator: 'generalpregunta_1',
          grouped: 'geoLocation',
          keyExtract: ['group', 'value',],
          label: 'group',
          value: 'value',
          singleton: true,
        },
      ],
    },
  },
  {
    title: 'Satisfacción General',
    position: [0, 2,],
    size: [1, 2,],
    config: {
      id: 'satisfaction-general-gauge',
      type: 'gauge',
      showHistoric: true,
      decimals: 1,
      indicators: [
        {
          indicator: 'generalpregunta_1',
          keyExtract: ['value',],
          value: 'value',
          historic: true,
        },
      ],
    },
  },
  {
    title: 'NPS',
    position: [1, 2,],
    size: [1, 2,],
    config: {
      id: 'generalpregunta_1',
      type: 'nps',
      'color': ['#CCC',],
      showHistoric: false,
      enableArcLabels: true,
      'valueFormat': ' >-.0%',
      indicators: [
        {
          indicator: 'generalpregunta_1',
          keyExtract: ['passives', 'detractors', 'promoters', 'value',],
          historic: true,
          extras: {},
        },
      ],
    },
  },
  */
   {
    title: 'Experiencia general en Consultorio',
    // [Column, Row]
    position: [0, 0,],
    size: [4, 2,],
    config: {
      id: 'customer-journey-farmacia',
      type: 'multipleGauge',
      fillMode: '',
      colors: ['#33b9ce',],
      showValues: true,
      showHistoric: false,
      indicators: [
        {
          indicator: 'ATRIBUTOS_XP_CONSULTORIO',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId',],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            criticalMomentId: 1,
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
   {
    title: '¿Estás enterado de los servicios que ofrecemos en Consultorios Médicos Benavides?',
    // [Column, Row]
    position: [0, 2],
    size: [2, 2],
    config: {
      id: 'consultorio_pregunta_6',
      type: 'pie',
      showValues: false,
      indicators: [
        {
          indicator: 'consultorio_pregunta_6',
          //grouped: 'geoLocation',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          /*extras: {
            criticalMomentInclude: [11701]
          },
          */
        },
      ],
    },
  },
  
   {
    title: 'Servicios que has utilizado en Consultorios Médicos Benavides',
    // [Column, Row]
    position: [2, 2],
    size: [2, 2],
    config: {
      id: 'consultorio_pregunta_7',
      type: 'pie',
      showValues: false,
      indicators: [
        {
          indicator: 'consultorio_pregunta_7',
          //grouped: 'geoLocation',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          /*extras: {
            criticalMomentInclude: [11701]
          },
          */
        },
      ],
    },
  },
  {
    title: 'Mejores etiquetas',
    position: [0, 4,],
    size: [2, 1,],
    config: {
      id: 'best-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive',],
          label: 'groupName',
          singleton: true,
          grouped: 'tag',
          sort: { value: 'desc', },
          limit: 1,
          extras: {
            resultType: 'top',
          },
        },
      ],
    },
  },
  {
    title: 'A mejorar en los comentarios',
    position: [2, 4,],
    size: [2, 1,],
    config: {
      id: 'worst-tags',
      type: 'ranking',
      indicators: [
        {
          indicator: 'SENTIMENT',
          keyExtract: ['groupName', 'positive',],
          label: 'groupName',
          singleton: true,
          grouped: 'tag',
          sort: { 'positive': 'desc', },
          limit: 1,
          extras: {
            resultType: 'bottom',
          },
        },
      ],
    },
  },
/*
  {
    title: 'Mejores farmacias evaluadas',
    position: [0, 6,],
    size: [2, 2,],
    config: {
      id: 'best-tags-general',
      type: 'ranking',
      indicators: [
        {
          indicator: 'generalpregunta_1',
          keyExtract: ['group', 'value',],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'desc', },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation',],
          },
        },
      ],
    },
  },
  {
    title: 'Farmacias a mejorar',
    position: [2, 8,],
    size: [2, 2,],
    config: {
      id: 'worst-tags-general',
      type: 'ranking',
      indicators: [
        {
          indicator: 'generalpregunta_1',
          keyExtract: ['group', 'value',],
          label: 'group',
          value: 'value',
          singleton: true,
          grouped: 'geoLocation',
          sort: { value: 'asc', },
          limit: 3,
          extras: {
            featureExclude: ['groupByTopLocation',],
          },
        },
      ],
    },
  },
  */
  {
    title: 'Análisis de sentimientos',
    position: [0, 5,],
    size: [4, 4,],
    config: {
      id: 'text-analysis',
      type: 'textAnalysis',
      indicators: [
        {
          indicator: 'SENTIMENT',
          grouped: 'tag',
          keyExtract: ['categories',],
          singleton: false,
          value: 'value',
        },
      ],
    },
  },
  {
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [0, 9,],
    size: [4, 2,],
    config: {
      id: 'word-count',
      type: 'wordCloud',
      indicators: [
        {
          indicator: 'WORD_COUNT',
          keyExtract: ['count', 'value', 'group',],
          singleton: true,
        },
      ],
    },
  },
   {
    title: '# de Encuestas por mes',
    helpLink: '--',
    position: [0, 11,],
    size: [4, 2,],
    config: {
      id: 'number-surveys-month',
      type: 'doubleAxisLine',
      colors: '#a7eb12',
      showHistoric: false,
      percentageScale: false,
      mapper: (data: any, extra: any): any => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          id: idx,
          group: item.group,
          label: item.groupName,
          value:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount']
              : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
        }))
      },
      indicators: [
        {
          indicator: 'SURVEY_COUNT',
          grouped: 'formattedMonth',
          keyExtract: ['group', 'value', 'groupName',],
          label: 'group',
          value: 'value',
          dataType: 'list',
          singleton: true,
          historic: false,
          sort: { 'sortBy': 'group', value: 'asc', },
        },
      ],
    },
  },

]
}
