import _ from 'lodash'
import { ApiResponse, ApisauceInstance } from 'apisauce'
import parseFilters from './parseFilters'
import parseQuery from './parseQuery'
// import formDataBuilder from './FormDataBuilder'

export type REST_API_ENDPOINTS<T> = {
  list: (page: number, size: number, filters?: FiltersUI, query?: object, sort?: string) => Promise<ApiResponse<T[], any>>
  create: (data: object) => Promise<ApiResponse<unknown, unknown>>
  update: (id: string | number, data: object) => Promise<ApiResponse<unknown, unknown>>
  retrieve: (id: string | number, extra?: object) => Promise<ApiResponse<T, unknown>>
  remove: (id: string | number) => Promise<ApiResponse<unknown | unknown>>
}

/**
 * Generates a base functions to hit an Rest Api entity with an apisauce instance.
 * @param {String} entity endpoint
 * @param {ApisauceInstance} apisauce instance
 * @return {Object}  */
export default <T>(entity: string, api: ApisauceInstance): REST_API_ENDPOINTS<T> => {
  return {
    list: (page: number, size: number, filters?: FiltersUI, query?: object, sort?: string ) => {
      return api.get(`${entity}`, {
        page,
        ...(size && { size, }),
        ...(query && !_.isEmpty(query) && { filter: parseQuery(query), }),
        ...(sort && { sort, }),
        ...( filters && parseFilters(filters)),
      })
    },
    retrieve: (id: string | number, extra?: object) =>  api.get(`${entity}/${id}`, { ...extra, }),
    create: (data: object) => api.post(`${entity}`, data),
    update: (id: string | number, data: object) => api.put(`${entity}/${id}`, { ...data, id, }),
    remove: (id: number | string) => api.delete(`${entity}/${id}`),
  }
}
