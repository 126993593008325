import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'

// Sync route definition
export default (store: Store) => ({
  path: '/suplier-exp',
  layout: true,
  getComponent: async () => {
    const SuplierExpLayout = await import(
      /* webpackChunkName: "suplier-exp-modules" */ './components/SuplierExpLayout'
    )
    const { reducer, rootSagas } = await import(
      /* webpackChunkName: "suplier-exp-modules" */ './SuplierExpModule'
    )

    injectReducer(store, { key: 'suplierExp', reducer })
    injectSagas(store, rootSagas)

    return {
      children: SuplierExpLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/',
      title: 'Emperiencia del Proveedor', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const SuplierExpView = await import(
          /* webpackChunkName: "suplier-exp-modules"*/ './components/SuplierExpView'
        )
        return {
          children: SuplierExpView.default,
        }
      }
    },
    
    // {
    //   title: '', // Here you can use a plain string o a transalation key
    //   path: '/other-sub-path',
    //   getComponent: async () => {
    //     const OtherView = await import(
    //       /* webpackChunkName: "suplier-exp-modules"*/ './components/OtherView'
    //     )
    //
    //     return {
    //       children: OtherView.default,
    //     }
    //   }
    // },
  ],
})

