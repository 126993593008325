import { filterGeos } from '@utils/GeoUtils'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsBenavides'

export default {
  title: 'Benamedic Dashboard',
  description: 'Dashboard de Benamedic',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'Experiencia general en Benamedic',
      position: [0, 0,],
      size: [4, 4,],
      mapper: (data: any, extra: any) => {
        return data
      },
      indicator: 'ATRIBUTOS_XP_BENAMEDIC',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Estás enterado de los servicios que ofrecemos en Benamedic?',
      position: [0, 4],
      size: [2, 2],
      indicator: 'benamedic_pregunta_9',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Servicios que has utilizado en Benamedic',
      position: [2, 4],
      size: [2, 2],
      indicator: 'benamedic_pregunta_10',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Mejores etiquetas',
      position: [0, 6],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Sentimiento negativo con más frecuencia',
      position: [2, 6],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),

    /*
    {
      title: 'Mejores farmacias evaluadas',
      position: [0, 8,],
      size: [2, 2,],
      config: {
        id: 'best-tags-general',
        type: 'ranking',
        indicators: [
          {
            key: 'generalpregunta_1',
            keyExtract: ['group', 'value',],
            label: 'group',
            value: 'value',
            singleton: true,
            grouped: 'geoLocation',
            sort: { value: 'desc', },
            limit: 3,
            extras: {
              featureExclude: ['groupByTopLocation',],
            },
          },
        ],
      },
    },
    {
      title: 'Farmacias a mejorar',
      position: [2, 8,],
      size: [2, 2,],
      config: {
        id: 'worst-tags-general',
        type: 'ranking',
        indicators: [
          {
            key: 'generalpregunta_1',
            keyExtract: ['group', 'value',],
            label: 'group',
            value: 'value',
            singleton: true,
            grouped: 'geoLocation',
            sort: { value: 'asc', },
            limit: 3,
            extras: {
              featureExclude: ['groupByTopLocation',],
            },
          },
        ],
      },
    },
    */

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      position: [0, 7],
      size: [4, 4],
      indicator: 'SENTIMENT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 11],
      size: [4, 2],
      indicator: 'WORD_COUNT',
    })/*,
    {
      title: '# de Encuestas por mes',
      helpLink: '--',
      position: [0, 13,],
      size: [4, 2,],
      config: {
        id: 'number-surveys-month',
        type: 'doubleAxisLine',
        colors: '#a7eb12',
        showHistoric: false,
        percentageScale: false,
        mapper: (data: any, extra: any): any => {
          const selectedGeos = filterGeos(
            extra['geoLocations'],
            extra['geoLocationsInclude']
          ).length

          return data.map((item: any, idx: any) => ({
            id: idx,
            group: item.group,
            label: item.groupName,
            value:
              item.value && item.value['answeredCount']
                ? item.value['answeredCount']
                : 0,
            trend:
              item.value && item.value['answeredCount']
                ? item.value['answeredCount'] / selectedGeos
                : 0,
          }))
        },
        indicators: [
          {
            key: 'SURVEY_COUNT',
            grouped: 'formattedMonth',
            keyExtract: ['group', 'value', 'groupName',],
            label: 'group',
            value: 'value',
            dataType: 'list',
            singleton: true,
            historic: false,
            sort: { 'sortBy': 'group', value: 'asc', },
          },
        ],
      },
    },*/
  ]
}
